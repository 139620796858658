import React from 'react'
import '../styles/NotFound.css'

const NotFound = () => {
  return (
    <div className='wrapper'>
      <h1>404 NOT FOUND</h1>
    </div>
  )
}

export default NotFound
