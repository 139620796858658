import { createBrowserRouter, RouteObject } from "react-router-dom";
import { HOME, NOTICE, PAYMENT_POLICY, POLICY, TERM } from "./configs/path";
import Home from "./pages/home";
import Notice from "./pages/Notice";
import Policy from "./pages/Policy";
import Term from "./pages/Term";
import PaymentPolicy from "./pages/PaymentPolicy";
import NotFound from "./pages/NotFound";

const routes: RouteObject[] = [
  // {
  //   path: HOME,
  //   element: <Home />,
  // },
  {
    path: TERM,
    element: <Term />,
  },
  // {
  //   path: NOTICE,
  //   element: <Notice />,
  // },
  // {
  //   path: POLICY,
  //   element: <Policy />,
  // },
  // {
  //   path: PAYMENT_POLICY,
  //   element: <PaymentPolicy />,  //Hidden at the momment
  // }
  {
    path: '*',
    element: <NotFound />
  }
];

const router = createBrowserRouter(routes);

export default router;
